
















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
    name: 'VsListLine',
})

export default class extends Vue {
    @Prop({
        required: false,
        default: 'medium',
    }) size!: 'medium' | 'small' | 'large'

    @Prop({
        required: false,
        default: '',
    }) label?: any

    @Prop({
        required: false,
        default: '',
    }) text?: string

    @Prop({
        required: false,
        default: false,
        type: Boolean,
    }) textRight!: boolean

    @Prop({
        required: false,
        default: true,
        type: Boolean,
    }) truncate!: boolean

    get labelClasses () {
        return {
            'vs-body-medium': this.size !== 'large' && this.size !== 'small',
            'vs-body-large': this.size === 'large',
            'vs-body-small': this.size === 'small',
        }
    }

    get textClasses () {
        return {
            'md:vs-text-right': this.textRight,
            'vs-truncate': this.truncate,
            'vs-h200': this.size !== 'large' && this.size !== 'small',
            'vs-h300': this.size === 'large',
            'vs-h100': this.size === 'small',
        }
    }
}
