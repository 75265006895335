import { serviceConsoleApi } from '@/utils/request'
const base = '/consents'

export const getConsents = (params?: any, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${base}`,
        {
            // signal,
            params,
        },
    )

export const exportConsents = (signal?: AbortSignal) =>
    serviceConsoleApi.defaults.baseURL + `${base}/export`

export const getConsentById = (consentId: any, signal?: AbortSignal) =>
    serviceConsoleApi.get(
        `${base}/${consentId}`,
        {
            // signal,
        },
    )

export const deleteConsent = (consentId: any, signal?: AbortSignal) =>
    serviceConsoleApi.delete(
        `${base}/${consentId}`,
        {
            // signal,
        },
    )
