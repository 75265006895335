



























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsListLine from '@/components/VsListLine/Index.vue'
import { getConsentById } from '@/api/consoleApi/consents'
import { copyToClipboard } from '@/utils/copyToClipboard'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsConsentDetailsDrawer',
    components: {
        VsSectionHeader,
        VsListLine,
    },
})
export default class extends Vue {
    @Prop({ default: '', required: false }) importId!: string
    private consent: any = null
    private open = false
    public loading = false
    $refs: any

    public async openDrawer (consentId: string) {
        this.loading = true
        this.open = true
        this.consent = null
        await this.getConsentById(consentId)
        this.loading = false
    }

    public closeDrawer () {
        this.open = false
    }

    private async getConsentById (importId: string) {
        try {
            const resp = await getConsentById(importId)
            this.consent = resp.data
        } catch (e) {
            this.consent = null
        }
    }

    private copyToClipboard (string: string) {
        try {
            copyToClipboard(string)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.codeCopied'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.sections.advanced.codeCopiedError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    private formatPayloadLabel (label: string) {
        switch (label) {
            case 'field_email': return 'Email: '
            case 'field_mobile': return 'Mobile: '
            default: return label + ': '
        }
    }
}
