















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { get } from 'lodash'
import { AppModule } from '@/store/modules/app'
import { deleteConsent, exportConsents, getConsents } from '@/api/consoleApi/consents'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsConsentDetailsDrawer from '@/modules/account/components/VsConsentDetailsDrawer/Index.vue'

@Component({
    name: 'Consents',
    components: {
        VsTable,
        VsDropdownButton,
        VsConsentDetailsDrawer,
    },
})
export default class extends Vue {
    consents = []
    loading = false
    total = 0
    checkedConsents = []
    filters = {
        search: '',
    }

    pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    get params () {
        return {
            search: `${this.filters.search}`,
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
        }
    }

    get exportConsents () {
        return exportConsents()
    }

    get user () {
        return UserModule.user
    }

    set user (val) {
        UserModule.SET_USER(val)
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get tableHeaders (): TableHeader[] {
        return [
            {
                id: 'timestamp',
                key: 'timestamp',
                label: 'Data registrazione',
                formatter: (value: any) => this.$options?.filters?.formatUnixDate(value),
                visible: true,
            },
            {
                id: 'idRecipient',
                key: 'idRecipient',
                label: 'Id lista',
                visible: true,
            },
            {
                id: 'idSubscriber',
                key: 'idSubscriber',
                label: 'Id contatto',
                visible: true,
            },
            {
                id: 'payloadSubmit.field_email',
                key: 'payloadSubmit.field_email',
                label: 'Email',
                visible: true,
            },
            {
                id: 'payloadSubmit.field_mobile',
                key: 'payloadSubmit.field_mobile',
                label: 'Mobile',
                visible: true,
            },
        ]
    }

    @Watch('params', { immediate: true })
    private async getConsents () {
        this.loading = true
        try {
            const resp = await getConsents(this.params)
            if (resp.data.meta.page > resp.data.meta.pages) {
                this.pagination.page = resp.data.meta.pages
            }
            this.consents = resp.data.data
            this.total = resp.data.meta.total
        } catch (e) {
            console.log(e)
            this.consents = []
            this.total = 0
        }
        this.loading = false
    }

    private async deleteConsent (consentId: any) {
        this.loading = true
        try {
            await deleteConsent(consentId)
            this.getConsents()
        } catch (e) {
            console.log(e)
            this.loading = false
        }
    }
}
